import './App.scss';
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesFile from './routes';


const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <RoutesFile />
      </Suspense>
    </Router>
  );
};

export default App;
