import React, { lazy, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { scrollToTop } from 'utils/basicUtills.js';


// Lazy loading the components
const LandingPage = lazy(() => import('./pages/landing-page/landingPage.jsx'));
const MainSolutionPage = lazy(() => import('./pages/main-solution-page/mainSolutionPage.jsx'));
const MarketingSolutionPage = lazy(() => import('./pages/marketing-solution-page/marketingSolutionPage.jsx'));
const DevSolutionPage = lazy(() => import('./pages/dev-solution-page/devSolutionPage.jsx'));
const DigitalSolutionPage = lazy(() => import('./pages/digital-solution-page/digitalSolutionPage.jsx'));
const AboutUsPage = lazy(() => import('./pages/about-us-page/aboutUsPage.jsx'));
const CareerPage = lazy(() => import('./pages/career-page/careerPage.jsx'));
const CaseStudiesPage = lazy(() => import('./pages/case-studies-page/caseStudiesPage.jsx'));
const DetailedCaseStudyPage = lazy(() => import('./pages/case-studies-page/detailedCaseStudyPage.jsx'));
const ContactPage = lazy(() => import('./pages/contact-us-page/contactUsPage.jsx'));
const DynamicAssessmentPage = lazy(() => import('./pages/assessment-pages/entryPoint.jsx'));
const NotFoundPage = lazy(() => import('./pages/not-found-page/not-found-page.jsx'));
const CertificatePage = lazy(() => import('./pages/certificate-page/certificatePage.jsx'));
const ClearStoragePage = lazy(() => import('./pages/assessment-pages/clearStoragePage.jsx'))



const RoutesFile = () => {
  const location = useLocation();

  // Define the animation for route transitions
  const pageVariants = {
    initial: {
      opacity: 0,
      x: "0",
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5, // duration of transition
  };

   // Scroll to top on route change with a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToTop();
    }, 300); // 0.3 seconds delay

    return () => clearTimeout(timer); // Cleanup the timeout on component unmount
  }, [location]);

  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route
          exact
          path="/"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <LandingPage />
            </motion.div>
          }
        />
        <Route
          path="/solutions"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <MainSolutionPage />
            </motion.div>
          }
        />
        <Route
          path="/solutions/marketing"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <MarketingSolutionPage />
            </motion.div>
          }
        />
        <Route
          path="/solutions/development"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <DevSolutionPage />
            </motion.div>
          }
        />
        <Route
          path="/solutions/digital-media"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <DigitalSolutionPage />
            </motion.div>
          }
        />

        <Route
          path="/our-company"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <AboutUsPage />
            </motion.div>
          }
        />
        <Route
          path="/our-company/career"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <CareerPage />
            </motion.div>
          }
        />

<Route
          path="/our-company/career/assessment/:assessmentId"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <DynamicAssessmentPage />
            </motion.div>
          }
        />

        <Route
          path="/resources/case-studies"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <CaseStudiesPage />
            </motion.div>
          }
        />

        <Route
          path="/resources/case-studies/case-study/:Brand"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <DetailedCaseStudyPage />
            </motion.div>
          }
        />
        <Route
          path="/contact-us"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <ContactPage />
            </motion.div>
          }
        />
        <Route
          path="/certificates/:id"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <CertificatePage />
            </motion.div>
          }
        />
        <Route
          path="/reset-assessment"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <ClearStoragePage />
            </motion.div>
          }
        />
        <Route
          path="*"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <NotFoundPage />
            </motion.div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default RoutesFile;


